import React, { useState, useEffect } from "react";
import axios from "axios";
import { navigate } from "gatsby-link";
import { LocaleAsString } from "../../localeEngine";
import IsUserLogedIn from "../../utils/isUserLogedIn";
import { Input } from "../../components/forms";
import { Typography, Container } from "../../components/utils";
import Layout from "../../components/layout";
import Button from "../../components/utils/button";
import Link from "../../components/link";
import { EMAIL_REGEX } from "../../components/forms/input/regex";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import { getSession, setSession } from "../../utils/localStorage";
import Icon from "../../components/icons";
import * as styles from "./styles.module.scss";

const SignIn = ({ location }) => {
  const FormDefaultState = {
    email: (location.state && location.state.email) || "",
    password: "",
  };
  const [form, setForm] = useState(FormDefaultState);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (IsUserLogedIn() && typeof window !== "undefined")
      navigate("/my-account", { state: { prevLocation: "sign-in" } });
  }, []);

  const onSubmit = (e) => {
    if (!checkInputs()) attemptLogin();
    e.preventDefault();
  };

  const handleChange = (name, event) => {
    const currentForm = { ...form };
    let value = event.target.value;
    currentForm[name] = value;

    setForm(currentForm);
  };

  const attemptLogin = () => {
    setLoading(true);
    axios
      .post(`${CONNECT}/checkout/login`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        data: {
          email: form.email,
          password: form.password,
        },
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      })
      .then((response) => {
        if (response.data && response.data.customer) {
          setSession({
            ...getSession(),
            customer: response.data.customer,
            purchaser: response.data.purchaser,
          });
          setLoading(false);
          navigateToNextPage({ state: { step: 2 } });
        } else {
          setError(true);
          setLoading(false);
        }
      });
  };

  const checkInputs = () => {
    if (EMAIL_REGEX.test(form.email) && form.password.length) {
      return false;
    }

    return true;
  };

  const navigateToNextPage = (state = false) => {
    if (
      location &&
      location.state &&
      location.state.prevLocation === "my-account"
    ) {
      navigate("/my-account", { state });
    } else {
      navigate("/checkout", { state });
    }
  };

  return (
    <Layout
      backgroundColor={"#fff"}
      backgroundColorDesktop={"#f4f4f9"}
      hideMobileFooter={true}
    >
      <Container maxWidth="lg" component="section" className={styles.signIn}>
        <div className={styles.signInHeader}>
          <Button
            className={styles.backBtn}
            onClick={() => navigateToNextPage()}
          >
            <Icon icon="ArrowLeft" size="Small" />
          </Button>
          <Typography
            variant="h500-medium"
            component={"span"}
            className={styles.title}
          >
            <LocaleAsString id="sign_in.header.log_in" />
          </Typography>
        </div>
        <form className={styles.form} onSubmit={onSubmit}>
          {error && (
            <Typography
              variant="h300-medium"
              component="span"
              className={styles.error}
            >
              <LocaleAsString id="sign_in.form.login_error" />
            </Typography>
          )}
          <Input
            className={styles.fullInput}
            label={<LocaleAsString id="sign_in.form.email" />}
            icon="Email"
            required
            name="email"
            type="email"
            onChange={(e) => handleChange("email", e)}
            value={form.email}
          />
          <Input
            className={styles.fullInput}
            label={<LocaleAsString id="sign_in.form.password" />}
            icon="Lock"
            required
            name="password"
            type="password"
            onChange={(e) => handleChange("password", e)}
            value={form.password}
          />
          <Link
            className={styles.forgot}
            url="/reset-password"
            state={{ email: form.email }}
          >
            <Typography variant={"h300-heavy"} component={"span"}>
              <LocaleAsString id="sign_in.form.forgot_password" />
            </Typography>
          </Link>
          <input type="submit" style={{ display: "none" }} />
        </form>
        <div className={styles.formCta}>
          <Button
            className={styles.loginButton}
            loading={loading}
            disabled={checkInputs()}
            onClick={() => attemptLogin()}
          >
            <Typography component="span" variant="h400-medium">
              <LocaleAsString id="sign_in.submit.cta" />
            </Typography>
          </Button>
          <div className={styles.disclaimer}>
            <Typography variant="h300-medium" component={"span"}>
              <LocaleAsString id="sign_in.submit.dont_have_account" />
            </Typography>
            <Link className={styles.toCheckout} url="/checkout">
              <Typography variant="h300-heavy" component={"span"}>
                <LocaleAsString id="sign_in.submit.sign_up" />
              </Typography>
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  );
};
export default SignIn;
